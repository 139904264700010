<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-2">
          <template #start>
            
              
              <Dropdown
                id="status"
                v-model="yearstatus"
                :options="yearItems"
                :value="yearItems.value"
                optionLabel="name"
                placeholder="Select Batch Year"
                :class="{ 'p-invalid': submitted && !selectstatus }"
                required="true"
                v-on:change="get_list"
              ></Dropdown>
              
              <!-- <Button
                icon="pi pi-search"
                class="p-button-success mr-2"
                @click="get_list"
              /> -->
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->

              <!-- <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="refresh"
              /> -->
            
          </template>

          <template #end>
            <!-- <FileUpload
              mode="basic"
              accept="application/pdf,image/jpeg,image/png"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll" style="font-size: 12px"
          :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Previous Lecture</b></h5>
               <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column headerStyle="min-width:3rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->
            </template>
          </Column>
            <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
          <Column header="Schedule" :field="columns[18]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.schedule }}
            </template>
          </Column>

          <Column header="Recorded Streaming">
            <template #body="slotProps">
              <!-- <Button
                class="p-button-rounded p-button-primary"
                @click="view_video(slotProps)"
              >
                View</Button
              > -->
<a  :href="slotProps.data.recordeD_VIDEO" target="_blank">
              <img
                :src="slotProps.data.recordeD_Image"
                :alt="slotProps.data.recordeD_Image"
                class="shadow-2"
                width="100"
                v-if="slotProps.data.recordeD_Image"
              />
              </a>

            </template>
          </Column>
          <Column header="Teacher" :field="columns[0]" style="min-width: 10rem;">
            <template #body="{ data }">
              {{ data.teacherName }}
            </template>
          </Column>
          <Column header="Course" :field="columns[2]" style="min-width: 10rem;">
            <template #body="{ data }">
              {{ data.batchName }}
            </template>
          </Column>
          <Column header="Subject" :field="columns[3]" style="min-width: 10rem;">
            <template #body="{ data }">
              {{ data.subjectName }}
            </template>
          </Column>
          <Column
            header="Decission on Topic"
           style="min-width: 10rem;"
            :field="columns[15]"
          >
            <template #body="{data}">
              {{ data.topiC_DESCRIPTION }}
            </template>
          </Column>


           <Column header="Study Material">
            <template #body="{data}">
               <!-- {{data.uploaD_DPP}} -->
             <span v-if="data.uploaD_CONTENT==''|| data.uploaD_CONTENT==null || data.uploaD_CONTENT==undefined" style="color:red">NO</span> 
             <span v-else style="color:green"> <a  :href="data.uploaD_CONTENT" target="_blank">
              view
              </a></span>
            </template>
          </Column>

          <Column header="Upload DPP">
            <template #body="{data}">
               <!-- {{data.uploaD_DPP}} -->
             <span v-if="data.uploaD_DPP==''|| data.uploaD_DPP==null || data.uploaD_DPP==undefined" style="color:red">NO</span> 
             <span v-else style="color:green"> <a  :href="data.uploaD_DPP" target="_blank">
              view
              </a></span>
            </template>
          </Column>


          <Column header="Upload DPP Answer">
            <template #body="{data}">
            
              <span v-if="data.uploaD_DPP_ANSWER==''|| data.uploaD_DPP_ANSWER==null || data.uploaD_DPP_ANSWER==undefined" style="color:red">NO</span> 
             <span v-else style="color:green"><a  :href="data.uploaD_DPP" target="_blank">
              view
              </a></span>
            </template>
          </Column>
          <Column header="View Student">
            <template #body="{data}">
             
              <Button
                class="p-button-rounded p-button-success p-button-outlined"
                @click="view_std(data)"
                
              >
                View</Button
              >
              
            </template>
          </Column>

          
        </DataTable>

        <Dialog
          v-model:visible="uploadproductDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Form on Teacher Lecture Schedule"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="discuessioN_ON_TOPIC">Discussion on topic</label>
                <InputText
                  id="discuessioN_ON_TOPIC"
                  v-model.trim="product.discuessioN_ON_TOPIC"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !product.discuessioN_ON_TOPIC,
                  }"
                />
              </div>
              <div class="field">
                <label for="file">Study Material</label>
                <FileUpload
                  mode="basic"
                  id="file_attachmentsm"
                  ref="file_attachmentsm"
                  @change="handleFileUploadsm"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                <br />
                <Button
                  label="Upload"
                  icon="pi pi-check"
                  class="p-button-success mr-1 mb-1"
                  @click="upload_doc_lec_sm"
                />
              </div>

              <div class="field">
                <label for="file">Upload DPP</label>
                <FileUpload
                  mode="basic"
                  id="file_attachmentdpp"
                  ref="file_attachmentdpp"
                  @change="handleFileUploaddpp"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                <br />
                <Button
                  label="Upload"
                  icon="pi pi-check"
                  class="p-button-success mr-1 mb-1"
                  @click="upload_doc_lec_dpp"
                />
              </div>

              <div class="field">
                <label for="file">Upload DPP Answer</label>
                <FileUpload
                  mode="basic"
                  id="file_attachmentdppA"
                  ref="file_attachmentdppA"
                  @change="handleFileUploaddppA"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                <br />
                <Button
                  label="Upload"
                  icon="pi pi-check"
                  class="p-button-success mr-1 mb-1"
                  @click="upload_doc_lec_dppa"
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="stdDialog"
          :style="{ width: '800px', width: '800px' }"
          header="Student Register For Live Class"
          :modal="true"
          class="p-fluid"
        >
          <DataTable
            :value="stdproducts"
            style="font-size: 12px"
            :paginator="true"
            class="p-datatable-gridlines"
            :rows="5"
            dataKey="id"
            :rowHover="true"
            v-model:filters="filters1"
            filterDisplay="menu"
            :loading="loading1"
            :filters="filters1"
            responsiveLayout="scroll"
            :totalRecords="stdproducts.length"
          >
            <Column header="name" style="min-width: 6rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.studentname }}
              </template>
            </Column>
            <Column header="mobile" style="min-width: 3rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.mobile }}
              </template>
            </Column>
            <Column header="email" style="min-width: 3rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.email }}
              </template>
            </Column>
            <Column
              header="register"
              style="min-width: 3rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                {{ data.register }}
              </template>
            </Column>
            <Column header="attend" style="min-width: 3rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.attend }}
              </template>
            </Column>
            <Column
              header="attendON"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                {{ data.attendON }}
              </template>
            </Column>
          </DataTable>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      uploadproductDialog: false,
      stdDialog: false,
      yearItems: [],
      yearstatus: { name: "", value: "" },

      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentsm: "",
      file_attachmentdpp: "",
      file_attachmentdppA: "",
      dropdownItems: [
        { name: "Motivational", value: "Motivational" },
        { name: "Interview", value: "Interview" },
      ],
      dropdownItems2: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      stdproducts: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "Active", value: "Active" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_year_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    upload_doc_lec_sm() {
      if (
        this.file_attachmentsm.type == undefined ||
        !this.file_attachmentsm.name
      ) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Upload study material",
          life: 3000,
        });
        return false;
      } else {
        //-----------------------------------------------------------------------------------
        var file_name2 = "";
        var d2 = new Date();
        file_name2 =
                  d2.getTime() +
                  "_" +
                  d2.getMilliseconds() +
                  "_" +
                  this.file_attachmentsm.name;

        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          UPLOAD_CONTENT:  file_name2,
          UPLOAD_CONTENT_BY: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promise = apis.uploadtchdetails(data);
        promise
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              if (this.file_attachmentsm.name) {

                var file_data={
                  filePath:"pt_assign/" +  file_name2,
                  contentType: this.file_attachmentsm.type

                }
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",
                // {
                //   filePath:"mahajyoti/studymaterial/" + this.file_attachmentsm.name,
                //   contentType: this.file_attachmentsm.type

                // }

                    
                //   )
                var promise2 = apis.uploadFile(file_data);
                promise2
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentsm, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentsm.type,
                        },
                      })
                      .then((response) => {
                        this.isLoadingModel = false;
                        this.uploadproductDialog = false;
                        this.$swal(responseapi.data.message);

                        

                        
                      })
                      .catch((error) => {
                        
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                this.isLoadingModel = false;
                this.uploadproductDialog = false;
                this.$swal(responseapi.data.message);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

               
              }
            } else {
              this.isLoadingModel = false;
              this.uploadproductDialog = false;
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              
            }
            //alert(this.totalRecords);
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
          });

        //------------------------------------------------------------------------------------
      }
    },
    upload_doc_lec_dpp() {
      if (
        this.file_attachmentdpp.type == undefined ||
        !this.file_attachmentdpp.name
      ) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Upload DPP",
          life: 3000,
        });
        return false;
      }
      else {
        //-----------------------------------------------------------------------------------
         var file_name2 = "";
        var d2 = new Date();
        file_name2 =
                  d2.getTime() +
                  "_" +
                  d2.getMilliseconds() +
                  "_" +
                  this.file_attachmentdpp.name;


        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          UPLOAD_ASSIGNMENT: file_name2,
          UPLOAD_ASSIGNMENT_BY: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promise = apis.uploadtchdetails(data);
        promise
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              if (this.file_attachmentdpp.name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "mahajyoti/studymaterial/" +
                //         this.file_attachmentdpp.name,
                //       contentType: this.file_attachmentdpp.type,
                //     }
                //   )
                var file_data={
                  filePath:"pt_assign/" + file_name2,
                  contentType: this.file_attachmentdpp.type

                }
                var promise2 = apis.uploadFile(file_data);
                promise2
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentdpp, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentdpp.type,
                        },
                      })
                      .then((response) => {
                          this.uploadproductDialog = false;
                        this.isLoadingModel = false;
                        this.$swal(responseapi.data.message);

                        this.productDialog = false;

                      
                      })
                      .catch((error) => {
                          this.uploadproductDialog = false;
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                  this.uploadproductDialog = false;
                this.$swal(responseapi.data.message);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
            //alert(this.totalRecords);
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
          });

        //------------------------------------------------------------------------------------
      }
    },
    upload_doc_lec_dppa() {
      if (
        this.file_attachmentdppA.type == undefined ||
        !this.file_attachmentdppA.name
      ) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Upload DPP Answer",
          life: 3000,
        });
        return false;
      }

      else {
        //-----------------------------------------------------------------------------------
        var file_name2 = "";
        var d2 = new Date();
        file_name2 =
                  d2.getTime() +
                  "_" +
                  d2.getMilliseconds() +
                  "_" +
                  this.file_attachmentdppA.name;


        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          UPLOAD_ANSWER: file_name2,
          UPLOAD_ANSWER_BY: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promise = apis.uploadtchdetails(data);
        promise
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              if (this.file_attachmentdppA.name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "mahajyoti/studymaterial/" +
                //         this.file_attachmentdppA.name,
                //       contentType: this.file_attachmentdppA.type,
                //     }
                //   )
                var file_data={
                  filePath:"pt_assign/" + file_name2,
                  contentType: this.file_attachmentdppA.type

                }
                var promise2 = apis.uploadFile(file_data);
                promise2
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentdppA, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentdppA.type,
                        },
                      })
                      .then((response) => {
                          this.uploadproductDialog = false;
                        this.$swal(responseapi.data.message);

                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                          this.uploadproductDialog = false;
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                  this.uploadproductDialog = false;
                this.$swal(responseapi.data.message);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
            //alert(this.totalRecords);
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
          });

        //------------------------------------------------------------------------------------
      }
    },
    view_video(video) {
     // alert(video)
      window.open(
        video.data.embeD_LINK,
        "_blank"
      );
    },
    view_std(info) {
     
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_year_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.yearItems = [];
          for (var i = 0; i < info.length; i++) {
            this.yearItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadsm() {
      this.file_attachmentsm = this.$refs.file_attachmentsm.files[0];

      //alert(this.$refs.file_attachmentsm.files[0].name);
    },
    handleFileUploaddpp() {
      this.file_attachmentdpp = this.$refs.file_attachmentdpp.files[0];

      //alert(this.$refs.file_attachmentdpp.files[0].name);
    },
    handleFileUploaddppA() {
      this.file_attachmentdppA = this.$refs.file_attachmentdppA.files[0];

      //alert(this.$refs.file_attachmentdppA.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (this.yearstatus.value) {
        var data = {
          //id:parseInt(localStorage.getItem("id")),
          type:"teacher",
          id: 261,
          Limit: this.limit,
          page_no: this.page_no,
          count: true,
          year: this.yearstatus.value,
        };
        this.loading = true;
        var promise = apis.yearlist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.yearstatus = { name: "", value: "" };
    },
    async get_list() {
      if (this.yearstatus.value) {
        var data = {
          // id:parseInt(localStorage.getItem("id")),
          type:"teacher",
          id: 261,
          Limit: this.limit,
          page_no: this.page_no,
          count: false,
          year: this.yearstatus.value,
        };
        this.loading = true;
        var promise = apis.previouslecture(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Addyear() {
      this.submitted = true;
      if (
        !this.product.enrollmenT_YEAR ||
        this.product.enrollmenT_YEAR.length > 4
      ) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Enrollment Year",
          life: 3000,
        });
        return false;
      }
      if (!this.product.exaM_YEAR || this.product.exaM_YEAR.length > 7) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Exam Year",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.mjyeaR_ID) {
        var data = {
          mjyeaR_ID: this.product.mjyeaR_ID,
          enrollmenT_YEAR: this.product.enrollmenT_YEAR,
          exaM_YEAR: this.product.exaM_YEAR,

          updated_by: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promise = apis.edityearmaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
          enrollmenT_YEAR: parseInt(this.product.enrollmenT_YEAR),
          exaM_YEAR: this.product.exaM_YEAR,
          createD_by: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promises = apis.addyearmaster(data1);
        promises
          .then((responseapi) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      this.selectstatus2 = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {   
      this.file_attachmentsm = "";
       this.file_attachmentdpp = "";
        this.file_attachmentdppA = "";
      this.product = { ...product };
      this.uploadproductDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachmentsm = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
